






























import { Component, Vue, Prop } from 'vue-property-decorator';
import { AssetType } from '@/scripts/models';
import { ApiAsset } from '@/scripts/apiAsset';
import * as firebase from 'firebase/app';
import 'firebase/storage';

// Notes/Googles:
// https://lovemewithoutall.github.io/it/vue-image-upload-to-firestorage/

@Component({
  components: {}
})
export default class AssetUploader extends Vue {
  @Prop({ required: true })
  private profileId!: string;

  @Prop({ required: true })
  private searchId!: string;

  @Prop({ required: true })
  public assetType!: AssetType;

  @Prop({ required: false, default: 4 })
  public maxFileSizeMb!: number;

  // Upload UI
  private uploadProgress = 0;
  private uploading = false;

  // Firebase upload
  private maxFileSize: number = 1024 * 1024 * this.maxFileSizeMb;
  private uploadTask!: firebase.storage.UploadTask;

  // Select a file (used from button click)
  private selectFile(): void {
    (this.$refs.fileInput as HTMLInputElement).click();
  }

  // Fired when file has been selected in file dialog
  private async fileSelected(eventData: any): Promise<void> {
    // Check for file size limit
    if (eventData.target.files[0].size < 1024 * 1024 * this.maxFileSizeMb) {
      const dt = new Date().getTime();
      await this.uploadAssetToCloud(eventData.target.files[0], '/' + this.searchId + '/' + dt.toString());
    } else {
      this.$emit('error-message', 'Fil må max. være ' + this.maxFileSizeMb + ' Mb');
    }
  }

  // Upload the file and set URL when done
  private async uploadAssetToCloud(file: any, fileName: string): Promise<void> {
    this.uploading = true;
    this.uploadTask = firebase.storage().ref(fileName).put(file);

    const unsubscribe = this.uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      // progress
      snapshot => {
        this.uploadProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      // error
      error => {
        this.$emit('error-message', 'Generel upload fejl: ' + error);
      },
      // complete
      async () => {
        this.uploadProgress = 100;
        this.uploading = false;
        (this.$refs.fileInput as HTMLInputElement).value = '';
        return this.uploadTask.snapshot.ref.getDownloadURL().then(async url => {
          try {
            await ApiAsset.postCreate({
              ProfileId: this.profileId,
              AssetType: this.assetType,
              AssetPath: url
            });
            this.$emit('upload-complete', 'Fil gemt');
          } catch (error) {
            this.$emit('error-message', 'Upload fejl:' + error.message);
          } finally {
            unsubscribe();
          }
        });
      }
    );
  }
}
















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IVisitResults } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';
import * as lib from '@/scripts/apiHelper.ts';

@Component({
  components: {}
})
export default class VisitorStats extends Vue {
  @Prop({ required: false })
  private profileId!: string;

  private data: IVisitResults[] = [];
  private visits: number[] = [0, 0];
  private labels: string[] = ['', ''];
  private maxVisits = 0;
  private minVisits = 0;
  private today: Date = new Date();
  private selectedMonth = '';
  private currentMonth = '';

  // Control variables
  private error = '';
  private loading = false;

  private async mounted(): Promise<void> {
    const year: number = this.today.getFullYear();
    let month: number = this.today.getMonth();
    month = month + 1; // Javascript starts January = 0

    this.currentMonth = year.toString() + '-' + month.toString().padStart(2, '0');
    this.selectedMonth = this.currentMonth;
    await this.loadData();
  }

  @Watch('selectedMonth')
  private async loadData(): Promise<void> {
    try {
      this.loading = true;
      // Get data from api
      this.data = await ApiProfile.visits(this.profileId, this.selectedMonth + '-01');
      // Transform data to only contain number array of visits (daily)
      this.visits = this.data.map(x => x.Visits);
      this.labels = this.data.map(x => x.Day);
      // Get min/max
      if (this.visits) {
        this.maxVisits = Math.max(...this.visits);
        this.minVisits = Math.min(...this.visits);
      } else {
        this.maxVisits = 0;
        this.minVisits = 0;
      }
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }
}

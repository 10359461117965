
































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as lib from '@/scripts/apiHelper.ts';
import * as sec from '@/scripts/security.ts';
import * as rules from '@/scripts/rules.ts';
import { IProfileResult, ICity, IProduct } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';
import { ApiCommon } from '@/scripts/apiCommon';

@Component({
  components: {}
})
export default class ProfileInfoEdit extends Vue {
  @Prop({ required: true })
  private profileId!: string;

  private profile: IProfileResult = {} as IProfileResult;
  private product: IProduct = {} as IProduct;
  private cities: ICity[] = [];

  private editing = false;

  private valid = false;

  private error = '';
  private saving = false;
  private loading = false;
  private snackbarShow = false;
  private counter = 0;

  public async mounted(): Promise<void> {
    if (sec.isJwtValid()) {
      try {
        this.loading = true;
        this.cities = await ApiCommon.getAllCities();
        this.profile = await ApiProfile.profileByProfileId(this.profileId);
        this.product = await ApiProfile.profileProduct(this.profileId);
      } catch (error) {
        this.error = lib.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    }
  }

  private editData(): void {
    this.editing = true;
    this.$emit('edit-mode', this.editing);
    this.$nextTick((this.$refs.description as HTMLInputElement).focus);
  }

  private async saveData(): Promise<void> {
    try {
      this.saving = true;
      await ApiProfile.update({
        ProfileId: this.profileId,
        ...this.profile
      });
      this.snackbarShow = true;
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.saving = false;
      this.editing = false;
      this.$emit('edit-mode', this.editing);
    }
  }

  get emailRules(): ((v: string) => boolean | string)[] {
    return rules.emailRules();
  }

  get webSiteRules(): ((v: string) => boolean | string)[] {
    return rules.websiteRules();
  }

  get phoneRules(): ((v: string) => boolean | string)[] {
    return rules.phoneRules(false);
  }
}

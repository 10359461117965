




























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { ApiProfile } from '@/scripts/apiProfile';
import AssetUploader from '@/components/Dashboard/AssetUploader.vue';
import AssetViewer from '@/components/Dashboard/AssetViewer.vue';
import * as lib from '@/scripts/apiHelper.ts';
import { IAsset, AssetType, IProduct } from '@/scripts/models';
import { ApiAsset } from '@/scripts/apiAsset';

@Component({
  components: { AssetUploader, AssetViewer }
})
export default class AssetManager extends Vue {
  @Prop({ required: true })
  private profileId!: string;

  @Prop({ required: true })
  private searchId!: string;

  // Profile / Product settings
  private product: IProduct = {} as IProduct;
  private productImages = 0;

  // Collections of assets for profile
  private avatars: IAsset[] = [{ AssetPath: '' }];
  private images: IAsset[] = [{ AssetPath: '' }];
  //  private videos: IAsset[] = [{ AssetPath: '' }];

  private error = '';
  private loading = false;

  private maxImageSize = 5;
  private maxAvatarSize = 2;

  // Snackbar
  private snackbarShown = false;
  private snackbarColor = '';
  private snackbarText = '';

  public created(): void {
    !firebase.apps.length
      ? firebase.initializeApp({
          apiKey: 'AIzaSyC_dL_3ePlpH4b5nvOU3qnyYIGhkQD_Mco',
          authDomain: 'dronepilot-e8957.firebaseapp.com',
          databaseURL: 'https://dronepilot-e8957.firebaseio.com',
          storageBucket: 'dronepilot-e8957.appspot.com'
        })
      : firebase.app();
  }

  public async mounted(): Promise<void> {
    try {
      this.loading = true;
      this.product = await ApiProfile.profileProduct(this.profileId);
      await this.loadData(AssetType.AVATAR);
      await this.loadData(AssetType.IMAGE);
      // await this.loadData(AssetType.VIDEO);
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }

  private async onDeletedAvatar(text: string): Promise<void> {
    this.showSnackbar(text, 'success');
    await this.loadData(AssetType.AVATAR);
  }

  private async onUploadedAvatar(text: string): Promise<void> {
    this.showSnackbar(text, 'success');
    await this.loadData(AssetType.AVATAR);
  }

  private async onDeletedImage(text: string): Promise<void> {
    this.showSnackbar(text, 'success');
    await this.loadData(AssetType.IMAGE);
  }

  private async onUploadedImage(text: string): Promise<void> {
    this.showSnackbar(text, 'success');
    await this.loadData(AssetType.IMAGE);
  }

  private async onError(text: string): Promise<void> {
    this.showSnackbar(text, 'error');
  }

  // Load initial data
  private async loadData(assetType: AssetType): Promise<void> {
    try {
      switch (assetType) {
        case AssetType.AVATAR:
          this.avatars = await ApiAsset.getAssetsByProfileIdandType(
            this.profileId,
            assetType,
            this.product.NumberProfilePictures
          );
          break;

        case AssetType.IMAGE:
          this.images = await ApiAsset.getAssetsByProfileIdandType(
            this.profileId,
            AssetType.IMAGE,
            this.product.NumberPictures
          );
          break;

        // case AssetType.VIDEO:
        //   this.videos = await ApiAsset.getAssetsByProfileIdandType(
        //     this.profileId,
        //     AssetType.VIDEO,
        //     this.product.NumberVideos
        //   );
        //   break;

        default:
          break;
      }
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    }
  }

  // Show snackbar
  private showSnackbar(text: string, color: string): void {
    this.snackbarText = text;
    this.snackbarColor = color;
    this.snackbarShown = true;
  }
}






















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ICompetenciesForProfileResult, ICompetencySelected } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';
import { ApiCommon } from '@/scripts/apiCommon';
import * as lib from '@/scripts/apiHelper.ts';

@Component({
  components: {}
})
export default class CompetenciesEdit extends Vue {
  @Prop({ required: false })
  private profileId!: string;

  // Tree
  private selectedItems = [];
  // List
  private selectedCompetencyNames: string[] = [];

  private competencies: ICompetenciesForProfileResult[] = [];

  // Control variables
  private editing = false;
  private valid = false;

  private error = '';
  private saving = false;
  private loading = false;
  private snackbarShow = false;

  public async mounted(): Promise<void> {
    try {
      this.loading = true;
      await this.getCompetencies();
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }

  private editData(): void {
    this.editing = true;
    this.$emit('edit-mode', this.editing);
  }

  // NOTE: flat() not compatible with Edge due to babl/polyfill where its
  // called flatten().....Wait for core-js 3. Find another way to implement this
  private async getCompetencies(): Promise<void> {
    this.competencies = await ApiCommon.getCompetenciesByProfileId(this.profileId);
    this.selectedItems = this.competencies
      .map(x => x.Competencies)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(x => x.Selected)
      .map(x => x.Id) as never[];
  }

  @Watch('selectedItems')
  private updateSelectedCompetenciesInList(): void {
    this.selectedCompetencyNames = this.getSelectedCompetencies().map(x => x.Name);
  }

  private getSelectedCompetencies(): ICompetencySelected[] {
    const selected = this.selectedItems.filter(x => x < 100000) as number[]; // Filter out root nodes
    //    const selectedComps = this.competencies.map(x => x.Competencies).flat(1);
    const selectedComps = this.competencies.map(x => x.Competencies).reduce((acc, val) => acc.concat(val), []);

    const newSelection: ICompetencySelected[] = [];
    selectedComps.forEach(element => {
      if (selected.includes(element.Id)) {
        newSelection.push(element);
      }
    });
    return newSelection;
  }

  private async saveData(): Promise<void> {
    try {
      this.saving = true;
      this.error = '';
      const compsToSave = this.getSelectedCompetencies()
        .map(x => x.Id)
        .toString();
      await ApiProfile.updateCompetencies({
        ProfileId: this.profileId,
        CompetencyList: compsToSave
      });

      // Reload competencies from db
      await this.getCompetencies();
      this.snackbarShow = true;
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.saving = false;
      this.editing = false;
      this.$emit('edit-mode', this.editing);
    }
  }
}

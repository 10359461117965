//
// Interface models and types
//

// Profile
export interface ISignupProfile {
  UserName: string;
  Password: string;
  ProductId: number;
  PhoneNumber: string;
  OTPCode: string;
}

export interface IRegion {
  RegionId: number;
  RegionName: string;
}

export interface ICity {
  CityId: number;
  ZipCode: string;
  CityName: string;
}

export interface ICategory {
  CategoryId: number;
  CategoryName: string;
}

export interface ICompetency {
  CompetencyId: number;
  CompetencyName: string;
}

export interface ICompetencySelected {
  Id: number;
  Name: string;
  Selected: boolean;
}

export interface ICompetenciesForProfileResult {
  Id: number;
  Name: string;
  Competencies: ICompetencySelected[];
}

export interface IProfileCompetenciesResult {
  CompetencyName: string;
}

export interface IProduct {
  ProductId: number;
  ProductName: string;
  ProductDescription: string;
  ProductMonthlyPrice: number;
  ProductYearlyPrice: number;
  ProductImage: string;
  NumberProfilePictures: number;
  NumberPictures: number;
  NumberVideos: number;
  ShowStatistics: boolean;
  ShowInTopSearchResults: boolean;
}

export interface ICouponCode {
  Discount: number;
}

export interface ISearchResult {
  SearchId: string;
  AvatarAsset: string;
  CompanyName: string;
  Description: string;
  IsPremium: boolean;
}

export interface IProfileCardResult {
  CompanyName: string;
  Description: string;
  Address: string;
  ZipCode: string;
  CityName: string;
  RegionName: string;
  ContactPerson: string;
  PhoneNumber: string;
  ContactEmail: string;
  WebSite: string;
  ProductId: number;
  AvatarImage: string;
  FacebookLink: string;
  YoutubeLink: string;
  DroneCertificate: boolean;
}

export interface IAdvertiser {
  SearchId: string;
  WebSite: string;
  AvatarAsset: string;
  CompanyName: string;
  Description: string;
}

export interface IActivationData {
  LinkCode: string;
}

export interface IActivationResult {
  Result: boolean;
}

export interface IAuthenticationData {
  UserName: string;
  Password: string;
}

export interface IAuthenticationResult {
  token: string;
  expiration: string;
}

export interface IProfileIdResult {
  ProfileId: string;
  SearchId: string;
}

export interface ICreationData {
  UserName: string;
  Password: string;
  ProductId: number;
  MonthlyAmount: number;
  PhoneNumber: string;
  OtpCode: string;
}

export interface ICreationResult {
  Link: string;
}

export interface IExistsResult {
  Result: boolean;
}

export interface IOTPData {
  PhoneNumber: string;
}

export interface IOTPSendResult {
  Result: boolean;
}

export interface IPageContent {
  Content: string;
}

export interface IProfileResult {
  UserName: string;
  CompanyName: string;
  Address: string;
  CityId: number;
  Description: string;
  ContactPerson: string;
  PhoneNumber: string;
  WebSite: string;
  ContactEmail: string;
  ProductId: number;
  AvatarImage: string;
  FacebookLink: string;
  YoutubeLink: string;
  DroneCertificate: boolean;
}

export interface IProfileUpdateData extends IProfileResult {
  ProfileId: string;
}

export interface IProfileUpdateResult {
  Result: boolean;
}

export interface IProfileVisitData {
  SearchId: string;
}

export interface IProfileVisitResult {
  Result: boolean;
}

export interface IProfileCompetenciesUpdateData {
  ProfileId: string;
  CompetencyList: string;
}

export interface IProfileCompetenciesUpdateResult {
  Result: boolean;
}

export interface ISubscriptionResult {
  Active: boolean;
  SubscriptionId: string;
  SubscriptionOrderId: string;
  MonthlyAMount: number;
  NextPayment: string;
  CreateDate: string;
}

export interface IPaymentsResult {
  PaymentId: string;
  Accepted: boolean;
  PaymentOrderId: string;
  Amount: number;
  Currency: string;
  CreateDate: string;
}

export interface IVisitResults {
  Day: string;
  Visits: number;
}

export enum AssetType {
  AVATAR = 'AVATAR',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export interface IAsset {
  AssetPath: string;
}

export interface IAssetCreationData {
  ProfileId: string;
  AssetType: string;
  AssetPath: string;
}

export interface IAssetCreationResult {
  Result: boolean;
}

export interface IAssetDeletionData {
  ProfileId: string;
  AssetPath: string;
}

export interface IAssetDeletionResult {
  Result: boolean;
}

export interface IPasswordRecoverData {
  UserName: string;
}

export interface IPasswordRecoverResult {
  Result: boolean;
}

export interface IPasswordResetData {
  LinkCode: string;
  NewPassword: string;
}

export interface IPasswordResetResult {
  Result: boolean;
}

export interface ISubscriptionStatusData {
  ProfileId: string;
  SubscriptionActive: boolean;
}

export interface ISubscriptionStatusResult {
  Result: boolean;
}

export interface IMenuItem {
  title: string;
  icon: string;
  link: string;
}




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ApiAsset } from '@/scripts/apiAsset';
import * as firebase from 'firebase/app';
import 'firebase/storage';

// Notes/Googles:
// https://lovemewithoutall.github.io/it/vue-image-upload-to-firestorage/

@Component({
  components: {}
})
export default class AssetViewer extends Vue {
  @Prop({ required: true })
  private profileId!: string;

  @Prop({ required: true })
  private assetPath!: string;

  private showDeleteQuestion = false;

  private async deleteCloudAsset(): Promise<void> {
    this.showDeleteQuestion = false;
    try {
      await ApiAsset.postDelete({
        ProfileId: this.profileId,
        AssetPath: this.assetPath
      });
      if (this.assetPath && this.assetPath.indexOf('static') < 0) {
        await firebase.storage().refFromURL(this.assetPath).delete();
      }
      this.$emit('delete-complete', 'Fil slettet');
    } catch (error) {
      this.$emit('error-message', 'Fejl i sletning af fil');
    }
  }
}

//
// A collection of helper functions and classes
//

import {
  ISubscriptionResult,
  IPaymentsResult,
  ISubscriptionStatusData,
  ISubscriptionStatusResult
} from '@/scripts/models';
import { getApi } from '@/scripts/apiHelper.ts';

// API calls
export class ApiSubscription {
  public static apiBase = 'subscription';
  // Subscription controller calls

  // Get subscription by profile id
  public static async getByProfileId(profileId: string): Promise<ISubscriptionResult> {
    const url = `${encodeURIComponent(profileId)}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as ISubscriptionResult;
  }

  public static async getPayments(profileId: string): Promise<IPaymentsResult[]> {
    const url = `payments?profileid=${encodeURIComponent(profileId)}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IPaymentsResult[];
  }

  public static async subscriptionStatus(payload: ISubscriptionStatusData): Promise<ISubscriptionStatusResult> {
    const response = await (await getApi(this.apiBase)).put('activestatus', payload);
    return response.data as ISubscriptionStatusResult;
  }
}

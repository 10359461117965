

























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IProduct, ISubscriptionResult, IPaymentsResult } from '@/scripts/models';
import { ApiProfile } from '@/scripts/apiProfile';
import { ApiSubscription } from '@/scripts/apiSubscription';
import * as lib from '@/scripts/apiHelper.ts';

@Component({
  components: {}
})
export default class SubscriptionEdit extends Vue {
  @Prop({ required: false })
  private profileId!: string;

  private product: IProduct = {} as IProduct;
  private subscription: ISubscriptionResult = {} as ISubscriptionResult;
  private payments: IPaymentsResult[] = [];

  // Control variables
  private canEdit = false;
  private error = '';
  private saving = false;
  private loading = false;
  private firstTimeDirty = true;
  private showStatusQuestion = false;

  // Payments controls
  private isMobile = false;
  private headers = [
    { text: 'Dato', align: 'left', sortable: true, value: 'CreateDate' },
    {
      text: 'Betalings id',
      align: 'left',
      sortable: false,
      value: 'PaymentId'
    },
    { text: 'Betalt', align: 'left', sortable: false, value: 'Accepted' },
    {
      text: 'Ordre id',
      align: 'left',
      sortable: false,
      value: 'PaymentOrderId'
    },
    { text: 'Beløb', align: 'right', sortable: false, value: 'Amount' },
    { text: 'Valuta', align: 'left', sortable: false, value: 'Currency' }
  ];
  private headersMobile = [
    { text: 'Dato', align: 'left', sortable: true, value: 'CreateDate' },
    { text: 'Betalt', align: 'left', sortable: false, value: 'Accepted' },
    { text: 'Beløb', align: 'right', sortable: false, value: 'Amount' }
  ];

  private onResize(): void {
    this.isMobile = window.innerWidth < 650;
  }

  public async mounted(): Promise<void> {
    try {
      this.loading = true;
      this.product = await ApiProfile.profileProduct(this.profileId);
      this.subscription = await ApiSubscription.getByProfileId(this.profileId);
      this.payments = await ApiSubscription.getPayments(this.profileId);
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }

  private async setSubscriptionStatus(value: boolean): Promise<void> {
    try {
      this.loading = true;
      await ApiSubscription.subscriptionStatus({
        ProfileId: this.profileId,
        SubscriptionActive: value
      });
      this.subscription.Active = value;
      this.showStatusQuestion = false;
    } catch (error) {
      this.error = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }
}














































import { Component, Vue } from 'vue-property-decorator';
import * as sec from '@/scripts/security.ts';
import { ApiProfile } from '@/scripts/apiProfile.ts';
import ProfileInfoEdit from '@/components/Dashboard/ProfileInfoEdit.vue';
import CompetenciesEdit from '@/components/Dashboard/CompetenciesEdit.vue';
import SubscriptionEdit from '@/components/Dashboard/SubscriptionEdit.vue';
import AssetManager from '@/components/Dashboard/AssetManager.vue';
import VisitorStats from '@/components/Dashboard/VisitorStats.vue';
import { IProduct } from '@/scripts/models';

@Component({
  components: {
    ProfileInfoEdit,
    CompetenciesEdit,
    SubscriptionEdit,
    VisitorStats,
    AssetManager
  }
})
export default class Dashboard extends Vue {
  private profileId = '';
  private searchId = '';
  private activeTab = 0;
  private tabsLocked = false;

  private product: IProduct = {} as IProduct;

  private async mounted(): Promise<void> {
    try {
      if (sec.isJwtValid()) {
        this.profileId = atob(window.sessionStorage.getItem('profileid') || '');
        this.searchId = atob(window.sessionStorage.getItem('searchid') || '');
        this.product = await ApiProfile.profileProduct(this.profileId);
        this.$emit('logged-in', true);
      } else {
        this.$emit('logged-in', false);
        window.location.href = '#/Login';
      }
    } catch (error) {
      alert('FEJL: ' + error);
    }
  }

  private lockTabs(locked: boolean): void {
    this.tabsLocked = locked;
  }
}
